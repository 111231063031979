import React from 'react'
import About from '../components/about/About'

const AboutPage = () => {
  return (
    <>
    <About />
    </>
  )
}

export default AboutPage